// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import clsx from 'clsx';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ChevronLeft from '../../assets/chevron-left.inline.svg';
import ChevronRight from '../../assets/chevron-right.inline.svg';
import { Card } from '../../components/glu/molecules/course-card';
import CatchUpWebinars from '../../components/glu/organisms/catch-up-webinars';
import { CardContainer } from '../../components/glu/organisms/course-container';
import SessionCardContainerCA from '../../components/glu/organisms/session-card-container-glu-ca';
import PageSection from '../../components/glu/organisms/page-section';
import TiltedCardContainer from '../../components/glu/organisms/tilted-card';
import { MainHeader } from '../../components/molecules/main-header';
import { GLULayout } from '../../layouts/glu';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/bundle';
import ArrowIcon from '../../assets/arrow.inline.svg';
import Spinner from '../../assets/spinner.inline.svg';
import { BreadCrumbs } from '../../components/atoms/breadcrumbs';
import { PBSLink } from '../../components/atoms/link';
import { LikeButton } from '../../components/glu/atoms/like-button';
import ScrollToTop from '../../components/glu/atoms/ScrollToTop/scrollToTop';
import ArticleNav from '../../components/glu/organisms/article-nav';
import { BannerNav } from '../../components/glu/organisms/banner-nav';
import { UnlockAccessForm } from '../../components/glu/organisms/unlock-access-form';
import { Head } from '../../components/templates/head';
import useFindCookie from '../../hooks/useCookie';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
	getOrganizationSeoSchema,
} from '../../utils/seo-schema';
import {
	getBreadcrumbs,
	getBreadcrumLocale,
	pageTypes,
} from '../../utils/url-helpers';

const GluClientPage = ({ data, location }) => {
	const {
		eLearningCoursesContent,
		featuredContent,
		featuredContentSlide,
		eLearningTitle,
		eLearningSessionCards,
		courses: courseLinks,
		locale,
		slug,
	} = data.datoCmsGluClientPage;

	const [currentIndex, setCurrentIndex] = useState(0);

	const handleNext = () => {
		if (currentIndex < courseLinks.length - 4) {
			setCurrentIndex(currentIndex + 1);
		}
	};

	const handlePrev = () => {
		if (currentIndex > 0) {
			setCurrentIndex(currentIndex - 1);
		}
	};

	const url = typeof window !== 'undefined' ? window.location.pathname : '';

	const { nodes: articles } = data.allDatoCmsBlogPage;
	const { nodes: gluBanner } = data.allDatoCmsGluBanner;
	const liveWebinarSessions = data.datoCmsGluClientPage.webinarSessions;

	const courseData = data.allDatoCmsGluCourse;
	const { mainLogo } = data.allDatoCmsGluLogo.nodes[0];
	const { mainLogo: imageLogo } = slug.includes('are-you-ready')
		? data.allDatoCmsGluLogo.nodes[1]
		: data.allDatoCmsGluLogo.nodes[0];

	const { nodes: allGluCoursePages } = data.allDatoCmsGluCourseLink;
	const { nodes: id } = data.allCourses;
	const allCourseArray = id.flatMap((e) => e.id);

	const headerCTAContent = {
		...data.datoCmsGluClientPage.hero,
		header:
			data.datoCmsGluClientPage?.hero?.header?.value?.document
				?.children || [],
	};

	const metaContent = {
		noIndex: data.datoCmsGluClientPage.noIndex,
		noFollow: data.datoCmsGluClientPage.noFollow,
		metaInformation: data.datoCmsGluClientPage.seo,
		fallbackTitle: data.datoCmsGluClientPage.eLearningTitle,
		fallbackDescription: '',
		canonicalUrl: data.datoCmsGluClientPage?.canonicalUrl || location?.href,
	};

	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			data,
			pageType: pageTypes.gluClientPage,
			location,
		}),
		image: getImageSeoSchema(data?.hero?.primaryImage?.url),
	};

	const onDemandCourses = data?.allDatoCmsGluCourse;
	const categoryIds = data.allCourseCategories.nodes;

	useMemo(() => {
		const allCourseCategories = [];
		const allCourseLinksCategories = [];

		[...categoryIds, ...allGluCoursePages].forEach((e) => {
			// eslint-disable-next-line compat/compat
			fetch(`/api/likes?item=${e.id}`)
				.then((res) => res.json())
				// eslint-disable-next-line no-shadow
				.then((courseData) => {
					const obj = {
						id: e.id,
						likes: courseData.likes,
					};
					if (obj.likes > 0) {
						if (
							allCourseArray.some((course) =>
								course.includes(e.id)
							)
						) {
							allCourseCategories.push(obj);
						} else {
							allCourseLinksCategories.push(obj);
						}
					}
				})
				// eslint-disable-next-line no-console
				.catch((err) => console.error(err));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onDemandRef = useRef();
	const coursesRef = useRef();
	const webcastRef = useRef(null);

	const scroll = (anchor) => {
		let top;
		switch (anchor) {
			case 'demand':
				top = onDemandRef;
				break;
			case 'courses':
				top = coursesRef;
				break;
			case 'webcast':
				top = webcastRef;
				break;
			default:
				top = null;
				break;
		}
		setTimeout(() => {
			window.scrollTo({
				top: top?.current?.offsetTop - 175,
				left: 0,
				behavior: 'smooth',
			});
		}, 100);
	};

	useEffect(() => {
		const isBrowser = typeof window !== 'undefined';
		if (isBrowser) {
			if (window.location.href.includes('#on-demand')) {
				scroll('demand');
			}
			if (window.location.href.includes('#courses')) {
				scroll('courses');
			}
			if (window.location.href.includes('#webcast')) {
				scroll('webcast');
			}
		}
	});
	const bannerTitle = 'Love it, Share it';

	const { locale: urlLocale } = data.datoCmsGluClientPage;
	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsGluClientPage.internal.type,
		data.datoCmsGluClientPage
	);

	const { hasCookie, findingCookie, setFindingCookie, setHasCookie } =
		useFindCookie({
			name: 'elearningContent',
		});
	const [consentResponse, setConsentResponse] = React.useState(false);
	React.useEffect(() => {
		if (consentResponse || hasCookie) {
			setFindingCookie(false);
			setHasCookie(true);
		}
	}, [consentResponse, hasCookie, setFindingCookie, setHasCookie]);
	let isSlug;
	switch (slug) {
		case 'hr-and-employment-law':
			isSlug = true;
			break;
		case 'health-and-safety':
			isSlug = true;
			break;
		case 'hr':
			isSlug = true;
			break;
		case 'employment-relations':
			isSlug = true;
			break;
		default:
			isSlug = false;
			break;
	}

	const removeHash = () => {
		if (window.location.hash) {
			window.history.pushState(
				'',
				document.title,
				window.location.pathname + window.location.search
			);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', removeHash);
		return () => {
			window.removeEventListener('scroll', removeHash);
		};
	}, []);
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{bannerTitle && locale === 'en-IE' && (
				<BannerNav title={bannerTitle} />
			)}
			{locale === 'en' && articles.length > 0 && (
				<ArticleNav
					bannerData={gluBanner}
					articleData={articles}
					locale={locale}
				/>
			)}
			<div className="bg-brand-pale-500 w-100">
				<BreadCrumbs breadCrumbItems={breadCrumbItems} glu />
			</div>
			{findingCookie ? (
				<div className="flex flex-row justify-center py-10">
					<Spinner
						width="24"
						height="24"
						fill="currentColor"
						className="ml-2 animate-spin"
					/>
				</div>
			) : (
				<>
					{hasCookie || consentResponse ? (
						<>
							{!url.includes('/ca/elearning/webinars') && (
								<MainHeader
									urlLocale={urlLocale}
									{...headerCTAContent}
									glu
									logo={imageLogo}
									rating={data.datoCmsTrustpilotRating}
								/>
							)}
							{locale === 'en-CA' &&
								url.includes('/ca/elearning/webinars') && (
									<SessionCardContainerCA
										liveWebinarSessions={
											liveWebinarSessions
										}
										locale={locale}
									/>
								)}
							{locale !== 'en-CA' &&
								featuredContent &&
								featuredContentSlide.length > 0 && (
									<PageSection
										theme="Inherit"
										titleBlock={featuredContent.value}
										className="py-12"
									>
										<Swiper
											modules={[
												Navigation,
												Pagination,
												A11y,
											]}
											spaceBetween={50}
											slidesPerView={1}
											navigation
											pagination={{ clickable: true }}
										>
											{featuredContentSlide.map(
												(course) => (
													<SwiperSlide
														key={course.id}
													>
														<div className="m-16 rounded-sm bg-brand-pale-400">
															<div className="flex flex-row p-6 sm:p-12">
																<div className="flex flex-col justify-between w-full lg:w-3/5">
																	<div>
																		<div className="flex gap-3">
																			<LikeButton
																				likes={
																					3042
																				}
																				courseId={
																					course.id
																				}
																			/>
																		</div>
																		<h3 className="my-6 text-lg font-bold sm:text-2xl">
																			{
																				course.title
																			}
																		</h3>

																		{course.intro && (
																			<p className="my-6 text-md sm:text-xl">
																				{
																					course.intro
																				}
																			</p>
																		)}
																	</div>

																	<div>
																		{course?.link && (
																			<PBSLink
																				to={
																					course.link
																				}
																				size="Small"
																				className="!text-sm lg:w-max w-full justify-center"
																				target="_blank"
																			>
																				Start
																				course{' '}
																				<ArrowIcon
																					viewBox="0 0 33 34"
																					className="w-4 h-auto ml-3 pt-[1px] -scale-x-100"
																				/>
																			</PBSLink>
																		)}

																		{course?.slug && (
																			<PBSLink
																				to={
																					course.slug
																				}
																				size="Small"
																				className="!text-sm w-full lg:w-max justify-center"
																			>
																				Watch{' '}
																				<ArrowIcon
																					viewBox="0 0 33 34"
																					className="w-4 h-auto ml-3 pt-[1px] -scale-x-100"
																				/>
																			</PBSLink>
																		)}
																	</div>
																</div>
																<div className="relative items-center hidden w-full lg:w-2/5 lg:flex">
																	<div className="m-auto ">
																		{course
																			.thumbnail
																			?.url ? (
																			<img
																				src={
																					course
																						.thumbnail
																						.url
																				}
																				alt={
																					course
																						.thumbnail
																						.url ||
																					''
																				}
																				className="border-8 border-white"
																			/>
																		) : (
																			<img
																				src={
																					mainLogo.url
																				}
																				alt="GLU logo"
																			/>
																		)}
																	</div>
																</div>
															</div>

															<div className="flex items-center px-12 py-3 text-white rounded-b-sm bg-brand-blue-400">
																{course.timeToWatchInMinutes && (
																	<>
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			width="19"
																			height="19"
																			fill="none"
																			className="inline-block mr-2"
																		>
																			<path
																				fill="#fff"
																				fillRule="evenodd"
																				d="M9.5 2.997a.527.527 0 0 0-.527.527v5.912c0 .29.236.527.527.527h5.517a.527.527 0 1 0 0-1.055h-4.99V3.524a.527.527 0 0 0-.527-.527Z"
																				clipRule="evenodd"
																			/>
																			<path
																				fill="#fff"
																				fillRule="evenodd"
																				d="M9.5.436c-4.966 0-9 4.034-9 9 0 4.965 4.034 9 9 9s9-4.035 9-9c0-4.966-4.034-9-9-9Zm0 1.054a7.94 7.94 0 0 1 7.946 7.946A7.94 7.94 0 0 1 9.5 17.38a7.94 7.94 0 0 1-7.946-7.945A7.94 7.94 0 0 1 9.5 1.49Z"
																				clipRule="evenodd"
																			/>
																		</svg>

																		{`Duration ${course.timeToWatchInMinutes} minutes`}
																	</>
																)}
															</div>
														</div>
													</SwiperSlide>
												)
											)}
										</Swiper>
									</PageSection>
								)}
							{locale !== 'en-CA' && courseLinks.length > 0 ? (
								<div ref={coursesRef}>
									<PageSection
										theme="Inherit"
										titleBlock={
											eLearningCoursesContent.value
										}
										logo={mainLogo}
										className=""
									>
										<div className="relative w-full">
											<div className="w-full overflow-hidden">
												<div
													className="flex transition-transform duration-300"
													style={{
														transform: `translateX(-${
															(currentIndex *
																100) /
															4
														}%)`,
													}}
												>
													<div className="my-4 md:flex w-full">
														{courseLinks.map(
															(course) => (
																<div className="my-4 md:flex glumsdsm:w-[18.2%]  glulg:w-[22%]  glulgx:w-[25%]  glumd:w-[16.45%] glumds:w-[17.3%] glumdx:w-[20%] gluxs:flex gluxs:w-full w-1/4 glumd:w-[23%] glumd:gap-0 p-2 glu2xl:w-1/4 flex-shrink-0">
																	<Card
																		cardContent={
																			course
																		}
																		key={
																			course.id
																		}
																		logo={
																			mainLogo
																		}
																		locale={
																			locale
																		}
																	/>
																</div>
															)
														)}
													</div>
												</div>
											</div>

											{courseLinks.length > 4 && (
												<>
													<button
														type="button"
														onClick={handlePrev}
														className={`absolute top-1/2 -left-12 glumd:-left-7 transform -translate-y-1/2 text-white  rounded-full p-2 ${
															currentIndex === 0
																? 'opacity-50 cursor-not-allowed'
																: ''
														}`}
														disabled={
															currentIndex === 0
														}
													>
														<ChevronLeft
															className={clsx(
																'w-7 h-7 text-brand-pale-500 cursor-pointer',
																currentIndex ===
																	0 &&
																	'text-grey-400 cursor-not-allowed'
															)}
														/>
													</button>
													<button
														type="button"
														onClick={handleNext}
														className={`absolute top-1/2 -right-12 glumd:-right-7  transform -translate-y-1/2 text-white rounded-full p-2 ${
															currentIndex >=
															courseLinks.length -
																4
																? 'opacity-50 cursor-not-allowed'
																: ''
														}`}
														disabled={
															currentIndex >=
															courseLinks.length -
																4
														}
													>
														<ChevronRight
															className={clsx(
																'w-7 h-7 text-brand-pale-500 cursor-pointer',
																currentIndex ===
																	courseLinks.length -
																		4 &&
																	'text-grey-400 cursor-not-allowed'
															)}
														/>
													</button>
												</>
											)}
										</div>
									</PageSection>
								</div>
							) : null}
							{isSlug && (
								<div ref={webcastRef}>
									<CardContainer
										cardContainerContent={{
											id: 'course-container',
										}}
										cardVariant="courseList"
										data={courseData}
										associatedCategory={
											courseData?.nodes?.[0]
												?.associatedCategory
										}
										webinarsOnly
										locale={locale}
									/>
								</div>
							)}
							<div ref={onDemandRef}>
								{/* {isSlug && (
									<div className="mx-auto max-w-m-screen">
										<div className="text-3xl text-brand-red-400 [text-align:center] rounded">
											<p>
												<span className="inline-block font-bold transition-transform duration-300 transform rounded flash-effect hover:scale-105 active:scale-95">
													Click Here For-{' '}
												</span>
												<Button
													href="#webcast"
													onClick={() =>
														scroll('webcast')
													}
													className="inline-block transform transition-transform duration-300 hover:scale-105 active:scale-95 m-2 !py-2 text-sm md:px-5 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-lexend-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-lexend-medium focus:outline-0 focus:shadow-focus"
												>
													Webinar Catch-Ups
												</Button>
											</p>
										</div>
									</div>
								)} */}
								<CardContainer
									cardContainerContent={{
										id: 'course-container',
									}}
									cardVariant="courseList"
									data={onDemandCourses}
									associatedCategory={
										onDemandCourses?.nodes?.[0]
											?.associatedCategory
									}
									locale={locale}
								/>
							</div>
							{!url.includes('/ca/elearning/webinars') && (
								<PageSection
									titleBlock={eLearningTitle.value}
									logo={mainLogo}
									className="pb-4"
								>
									<TiltedCardContainer
										cards={eLearningSessionCards}
										className="pb-0"
										locale={locale}
									/>
								</PageSection>
							)}
							{!url.includes('/ca/elearning/webinars') && (
								<PageSection>
									<CatchUpWebinars
										data={data.catchUpWebinar}
										locale={locale}
									/>
								</PageSection>
							)}
						</>
					) : (
						<div className="flex flex-row items-center justify-center w-full py-10">
							<div className="w-full lg:max-w-xl lg:shadow-xl ">
								<UnlockAccessForm
									pageUrl={
										typeof window !== 'undefined'
											? window.location.href
											: ''
									}
									locale={locale}
									headerContent="Your expert e-learning content is waiting for you"
									subTextContent="Whether you're a client or not, access your e-learning sessions by sharing your details:"
									setConsentResponse={setConsentResponse}
									className="!rounded-none"
								/>
							</div>
						</div>
					)}
					{locale === 'en' && <ScrollToTop className="mt-10 ml-10" />}
				</>
			)}
		</GoogleReCaptchaProvider>
	);
};
const WrappedGluClientPage = ({ data, location }) => (
	<GLULayout
		locale={data.datoCmsGluClientPage.locale}
		rating={data.datoCmsTrustpilotRating}
	>
		<GluClientPage data={data} location={location} />
	</GLULayout>
);

export default WrappedGluClientPage;

export const query = graphql`
	query GluClientPage($slug: String!, $title: String, $locale: String!) {
		datoCmsGluClientPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			id: originalId
			noIndex
			noFollow
			locale
			internal {
				type
			}

			seo {
				title
				description
				image {
					url
				}
				twitterCard
			}
			title
			slug
			hero {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				thirdLinkCopy
				thirdLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
			}
			featuredContent {
				value
			}
			featuredContentSlide {
				... on DatoCmsGluCourse {
					id
					locale
					title
					slug
					intro
					category {
						title
						slug
					}
					thumbnail {
						alt
						url
					}
				}
				... on DatoCmsGluCourseLink {
					id
					locale
					title
					intro
					link
					category {
						title
						slug
					}
					thumbnail {
						alt
						url
					}
				}
			}
			eLearningCoursesContent {
				value
			}
			courses {
				id: originalId
				link
				title
				intro
				showCpdLogo
				associatedCategory
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 412
						height: 162
					)
				}
			}
			webinarSessions {
				title
				highlightedLiveWebinar {
					title
					dateAndTime
					timeEnd
					timeZone
					slug
					shortenedContent {
						value
					}
				}
				image {
					gatsbyImageData(placeholder: DOMINANT_COLOR)
					alt
				}
			}
			eLearningTitle {
				value
			}
			eLearningSessionCards {
				title
				image {
					gatsbyImageData
					alt
				}
				description {
					value
				}
				ctaLink
				ctaText
			}
		}
		datoCmsTrustpilotRating(locale: { eq: $locale }) {
			id
			trustscore {
				id
				originalId
				stars
				trustscore
			}
		}
		allDatoCmsGluBanner(filter: { locale: { eq: $locale } }) {
			nodes {
				link
				id
				title
				viewTitle
				enableFlashing
			}
		}
		allDatoCmsBlogPage(
			limit: 5
			sort: { order: DESC, fields: meta___createdAt }
			filter: { locale: { eq: $locale } }
		) {
			nodes {
				title
				slug
				categories {
					slug
				}
			}
		}
		filteredCourses: allDatoCmsGluCourse(
			limit: 3
			filter: {
				category: { title: { eq: $title } }
				locale: { eq: $locale }
			}
		) {
			nodes {
				id: originalId
				title
				intro
				slug
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 350
						height: 350
					)
				}
				category {
					title
					slug
				}
				timeToWatchInMinutes
			}
		}
		allDatoCmsGluCourse(
			sort: { order: ASC, fields: title }
			limit: 1
			filter: {
				associatedCategory: { eq: $title }
				isWebinarCatchUp: { ne: true }
				hideInOnDemandLibrary: { ne: true }
				locale: { eq: $locale }
			}
		) {
			totalCount
			nodes {
				associatedCategory
			}
		}
		allDatoCmsGluLogo(filter: { locale: { eq: "en" } }) {
			nodes {
				title
				mainLogo {
					url
					alt
				}
			}
		}
		allDatoCmsGluCourseLink(
			filter: {
				associatedCategory: { eq: $title }
				locale: { eq: $locale }
			}
		) {
			nodes {
				id: originalId
				link
				title
				intro
				associatedCategory
				showCpdLogo
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 412
						height: 162
					)
				}
			}
		}
		allCourseCategories: allDatoCmsGluCourse(
			sort: { order: ASC, fields: title }
			filter: {
				associatedCategory: { eq: $title }
				isWebinarCatchUp: { ne: true }
				hideInOnDemandLibrary: { ne: true }
				locale: { eq: $locale }
			}
		) {
			nodes {
				id: originalId
			}
		}
		allCourses: allDatoCmsGluCourse(filter: { locale: { eq: $locale } }) {
			nodes {
				id
			}
		}
		catchUpWebinar: datoCmsCatchUpWebinar(locale: { eq: $locale }) {
			catchUpTitle {
				value
			}
			catchUpWebinarsDescription {
				value
			}
			catchUpWebinarsCta
			catchUpWebinarsCtaSlug
		}
	}
`;
